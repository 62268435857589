<template>
    <v-card>
        <v-card-title>
            <s-toolbar>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </s-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="12"
                    lg="12"
                    md="12"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >
                    </s-scanner-qr>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        dense
                        :items="itemsPalletDetail"
                        :headers="HeaderPalletDetail"
                        :items-per-page="-1"                        
                        disable-sort    
                        show-select      
                        item-key="CidID"                        
                        v-model="itemPalletContent"               
                        hide-default-footer>
                        <template v-slot:item.NumberBoxes="{ item }">
                            <s-text v-model="item.NumberBoxes" :min="0" type="number" @input="UpdateWeight(item)"  >

                            </s-text>
                        </template>
                        <template v-slot:item.IsObserved="{ item }">
                            <tr v-if="item.IsObserved == 1">
                               <v-chip x-small class="ma-2" color="red" text-color="white"  @click="CargarObservacion(item)">
                                    SI
                               </v-chip>
                            </tr>
                            <tr v-if="item.IsObserved != 1">
                               <v-chip x-small class="ma-2" color="green" style="font-size: 10px;font-weight: bold;" text-color="white">
                                    NO
                               </v-chip>
                            </tr>
                        </template>
                        
                    </v-data-table>
                </v-col>
            </v-row>
           
            <v-row>
                <v-col>
                    <s-select
                        :items="listChamber"
                        label="Camaras"
                        placeholder="Digite Usuario"
                        item-text="CdtDescription"
                        item-value="CdtID"
                        v-model="SelectChamber"
                        return-object      
                        @input="click($event)"                              
                        >
                    </s-select>
                </v-col>
                <v-col>
                    <s-select
                        :items="SelectChamber.tunnelsDetails"
                        label="Lado"
                        placeholder="Digite Usuario"
                        item-text="SideDescription"
                        item-value="CddID"
                        v-model="SelectSide"
                        return-object 
                        @input="clickSide($event)"                                   
                        >
                    </s-select>
                </v-col>
                <v-col cols="auto">
                    <s-select 
                        clearable
                        label="Carril"
                        item-text="Name"
                        item-value="Index"
                        :items="carriles"
                        v-model="FilterCarril"
                        @input="filterPallets()">

                    </s-select>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="auto" v-for="(item, indexNumberLanes) in SelectSide.NumberLanes" 
                                            :key="indexNumberLanes" :value="item" >
                    <v-card theme="dark" hover >
                        
                        <p hidden v-if="FilterCarril > 0" > {{ indexNumberLanes = FilterCarril - 1 }}</p>
                                <v-toolbar class="flex-grow-0" dark color="#5edf87" height="30"  rounded >
                                    <v-toolbar-title>{{ 'Carril ' +  parseInt(item + SelectSide.NumberStart)}}</v-toolbar-title>
                                </v-toolbar>
                            
                            <v-card-text>
                                <v-row dense v-for="(nivel, indexNumberLevels) in SelectSide.NumberLevels" 
                                                v-bind:key="indexNumberLevels" :value="nivel">
                                    <v-col  cols="auto"  class="py-1 pe-0">
                                        <v-card hover color="#f4edeb" >
                                            <v-toolbar color="#299ff9" height="30" shaped >
                                                <v-toolbar-title>{{ Position[indexNumberLevels] }}</v-toolbar-title>
                                            </v-toolbar>
                                            
                                            <v-row class="mt-1" dense>
                                                <v-col cols="auto"  v-for="(posicion, indexPositionsLevel) in SelectSide.PositionsLevel" 
                                                    v-bind:key="indexPositionsLevel" :value="posicion">
                                                    <v-card  v-if="SelectChamber.Pallest?.filter((x) =>  
                                                                        x.SideID == SelectSide.SideID
                                                                        && x.NumberLanes === indexNumberLanes 
                                                                        && x.NumberLevels === indexNumberLevels
                                                                        && x.PositionsLeve === indexPositionsLevel).length == 1"
                                                        
                                                        color="red accent-2" 
                                                        hover    
                                                        @click="detailPalletInPosition(SelectSide.SideID, indexNumberLanes, indexNumberLevels, indexPositionsLevel)"                                                                                                             
                                                        >
                                                        <v-card-text>
                                                            {{  posicion }}                                                                        
                                                        </v-card-text>
                                                    </v-card>

                                                    <v-card  v-else                                                        
                                                        :color="indexNumberLanes == selectNumberLane
                                                                && indexNumberLevels == selectNumberLevel 
                                                                && indexPositionsLevel == selectPositionsLevel ? '#7bc6fe' : '#f0faf7'" 
                                                        hover 
                                                        @click="Probar(indexNumberLanes, indexNumberLevels, indexPositionsLevel)"
                                                        >
                                                        <v-card-text>
                                                            {{  posicion }}                                                                        
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        <!-- </v-container> -->
                    </v-card>                            
                </v-col>
            </v-row>
            
        </v-card-text>
        <v-card-actions class="px-0 contendor-card">
            <v-col cols="auto">
                <v-btn small  color="primary" class="btn-flotante" dark @click="SaveNewPallet()">
                    <v-icon left small>fas fa-save</v-icon> Guardar
                </v-btn>  
            </v-col>            
        </v-card-actions>
        <v-row justify="center" cols="12" style="display: none">
            <v-col cols="12">
                <qr-code
                    :text="
                        PalletNew.PalletGroupID + ''
                        "
                    error-level="H"
                    :size="180"
                    hidden
                >
                </qr-code
                ><br />
            </v-col>
        </v-row>
        <v-dialog 
            v-model="dialogObservation"
            v-if="dialogObservation"
            max-width="350"
            >
            <v-card outlined>
                <!-- <v-card-title class="title font-weight-light" >     -->
                <s-toolbar color="primary" dark close @close="dialogObservation = false" label="Descripcion de Observacion">

                </s-toolbar>
                <!-- </v-card-title> -->
                <v-card-text>
                    <v-row class="s-col-form">
                        <v-col>
                            <v-row>
                                <v-col>
                                    <h2>Observacion: </h2>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h3>{{ this.ObservedString }}</h3>
                                </v-col>                                    
                            </v-row>
                        </v-col>
                    </v-row>
                    
                    
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogDetallePalletInPosition"
            v-if="dialogDetallePalletInPosition"
            persistent
            width="unset"
        >
            <detail-pallet 
                :sider= sider
                :lane = lane
                :level = level
                :pst = pst
                :CdtID = this.SelectChamber.CdtID
                @closeDetallePalletInPosition="closeDetallePalletInPosition()">
            </detail-pallet>
        </v-dialog>  
    </v-card>
    
</template>
<script>
    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";
    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';
    import qrCode from "vue-qr-generator";
    import DetailPallet from '../../FrzCamera/FrzChamberViewGraphics/FrzChamberDetailPalletInPosition.vue';

    import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
    export default {
        components: {qrCode, DetailPallet},
        data(){
            return {
                clearInput:false,
                configScann: {}, 
                itemsPalletDetail: [],
                HeaderPalletDetail: [
                    { text:"Grupo ID", value :"PalletGroupID"},
                    { text: "Trazabilidad", value: "TraceabilityID"},
                    { text: "Lote", value: "Lote"},
                    { text: "Cliente", value: "CumNombre"},
                    { text: "Marca", value: "BransName"},
                    { text: "Cultivo", value: "TypeCultiveName"},
                    { text: "Variedad", value: "VrtDescription"},
                    { text: "Tipo de Corte", value: "TypeCutName"},
                    { text: "Presentacion", value: "TypePresentationName"},
                    { text: "N° Cajas", value: "NumberBoxes",  width: "100px" },
                    { text: "Peso Neto", value: "WeightNetBoxes"},
                    // { text: "Condicion", value: "IsObservedString"},
                    { text: "T. Congelamiento", value: "CecDescription"},
                    { text: "Localizacion", value: "PalletLocation" },
                    { text: "E. Final", value: "StateFinalString" },
                    // { text: "E. Final", value: "StateFinal" },
                    { text: "Observado", value: "IsObserved" },
                    // { text: "Guardar Rol", value: "saveRoll", align: "center"},


                ],  
                listChamber:[],
                SelectChamber: {},
                SelectSide: {},
                Position: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
                selectNumberLane: null,
                selectNumberLevel: null,
                selectPositionsLevel: null,
                PalletNew: {},
                itemPalletContent: [],
                carriles: [],
                FilterCarril: 0,
                Validate: true,
                ObservedString: "",
                dialogObservation: false,
                dialogDetallePalletInPosition: false,
                sider : 0,
                lane : 0,
                level : 0,
                pst : 0,
            }
        },
        methods:{
            detailPalletInPosition(sider,lane,level,pst){
                
                this.dialogDetallePalletInPosition = true
                this.sider = sider;
                this.lane = lane;
                this.level = level;
                this.pst = pst;    
                        
            },
            closeDetallePalletInPosition(){
                this.dialogDetallePalletInPosition = false;
            },
            CargarObservacion(item)
            {
                
                this.ObservedString = item.Observation;
                this.dialogObservation = true;

            },
            closedialogObservation()
            {
                this.ObservedString = "";
                this.dialogObservation = false;
            },
            click(SelectChamber){
                this.SelectSide = {};

            },
            clickSide(side){
               
                this.FilterCarril = 0;
                this.carriles = [];
                this.SelectSide = side;
                for(let i = side.NumberStart + 1; i <= side.NumberLanes + side.NumberStart; i++)
                {
                    let carril = {}
                    carril.Index = i;
                    carril.Name = 'Carril ' + i;
                    this.carriles.push(carril);
                }               
            },
            filterPallets()
            {                     
                if(this.FilterCarril > 0 && this.FilterCarril != null)
                {         
                    this.isShow = true;
                    this.isFilter = false;
                    this.SelectSide.NumberLanes = 1;
                    this.PaletsFilters = [];
                    this.SelectSide.NumberStart = this.FilterCarril - 1;
                }

                if (this.FilterCarril == 0 || this.FilterCarril == null)
                {                      
                    this.SelectSide = {};       
                    this.initialize();  
                }     
            },
            onValue(val)
            {
                _sFrzCameraIncomePallet.getPalletByGroupID({PalletGroupID: val}, this.$fun.getUserID())
                .then((r) => {
                    if(r.status == 200)
                    {
                        if(r.data.length == 0){
                            this.$fun.alert("Pallet no existe", "warning");
                            return;
                        }
                        
                        
                        r.data[0].palletDetailCEs.forEach(element => {     
                            element.Lote = r.data[0].Lote;
                            element.NumberBoxesOld = element.TotalBox;  
                            element.WeightNetBoxesOld = parseFloat(element.TotalBagWeight);  
                            element.NumberBoxes = element.TotalBox;         
                            element.WeightNetBoxes = element.TotalBagWeight;  
                            element.CecDescription = r.data[0].CecDescription;   
                            element.BransName = r.data[0].BransName; 
                            element.PalletLocation = r.data[0].PalletLocation;   
                            element.PalletGroupID = r.data[0].PalletGroupID;  
                            element.CumNombre = r.data[0].CumNombre; 
                            element.CumID = r.data[0].CumID;      
                            element.CmaID = r.data[0].CmaID; 
                            element.TypeCut = r.data[0].TypeCut;    
                            element.CbdID = r.data[0].CbdID;    
                            element.SideID = r.data[0].SideID; 
                            element.NumberLanes = r.data[0].NumberLanes;     
                            element.NumberLevels = r.data[0].NumberLevels;   
                            element.PositionsLeve = r.data[0].PositionsLeve;    
                            element.CdtID = r.data[0].CdtID;   
                            element.TypeObservation = element.TypeObservation;
                            element.TypeGroup = element.TypeGroup;
                            element.TypeSubGroup = element.TypeSubGroup;
                            
                            // element.TypeVariant = r.data[0].TypeVariant;    
                            // element.TypeProduction = r.data[0].TypeProduction; 
                            // element.CipID = r.data[0].CipID; 
                            // element.StateFinal = r.data[0].StateFinal; 
                            this.itemsPalletDetail.push(element);                    
                        });  
                        
                    }
                });
            },
            UpdateWeight(item){
                
                if(parseInt(item.NumberBoxes) > item.NumberBoxesOld)
                {
                    this.Validate = false;
                    this.$fun.alert("El numero de cajas no puede ser mayor a " + item.NumberBoxesOld, "error");
                    return;
                }
                if(parseInt(item.NumberBoxes) == 0 || parseInt(item.NumberBoxes) == undefined)
                {
                    this.Validate = false;
                    this.$fun.alert("Ingrese numero de caja valido " , "error");
                    return;
                }
                this.Validate = true;
                item.WeightNetBoxes = (item.WeightNetBoxesOld / item.NumberBoxesOld) * item.NumberBoxes;
            },
            SaveNewPallet()
            {
                if(this.itemPalletContent.length > 0)
                {
                    let newPallet = {};
                    let numberBox = 0;
                    let WeightNetBox = 0;
                    let PalletGroupIDS = ""; //
                    
                    this.itemPalletContent.forEach(element => {
                        numberBox = numberBox + parseInt(element.NumberBoxes);
                        WeightNetBox = WeightNetBox + element.WeightNetBoxes;
                        element.TotalBox = parseInt(element.NumberBoxes);
                        if(!PalletGroupIDS.includes(element.PalletGroupID))
                        {
                            PalletGroupIDS = PalletGroupIDS + element.PalletGroupID + ",";
                        }
                        element.TotalBagWeight = element.WeightNetBoxes;
                    });

                    newPallet.CdtID = this.SelectChamber.CdtID;
                    if(newPallet.CdtID == undefined)
                    {
                        this.$fun.alert("Por Favor Seleccione Camara ", "warning");
                        return;
                    }

                    if(this.SelectSide.SideID == undefined)
                    {
                        this.$fun.alert("Por Favor Seleccione Lado ", "warning");
                        return;
                    }
                    
                    if(this.selectNumberLane == null)
                    {
                        this.$fun.alert("Por Favor Seleccione un Carril ", "warning");
                        return;
                    }
                    if(this.selectNumberLevel == null)
                    {
                        this.$fun.alert("Por Favor Seleccione un Nivel ", "warning");
                        return;
                    }
                    if(this.selectPositionsLevel == null)
                    {
                        this.$fun.alert("Por Favor Seleccione una Posicion ", "warning");
                        return;
                    }
                    newPallet.NumberBoxes = numberBox;
                    newPallet.WeightNetBoxes = WeightNetBox
                    newPallet.TypeCultive = this.itemPalletContent[0].TypeCultive;
                    newPallet.PalletGroupIDS = PalletGroupIDS.substring(0, PalletGroupIDS.length - 1);
                    newPallet.VrtID = this.itemPalletContent[0].VrtID;
                                     
                    newPallet.SideID = this.SelectSide.SideID;
                    newPallet.NumberLanes = this.selectNumberLane;
                    newPallet.NumberLevels = this.selectNumberLevel;
                    newPallet.PositionsLeve = this.selectPositionsLevel;
                    newPallet.UsrCreateID = this.$fun.getUserID();
                    newPallet.TypePacking = this.itemPalletContent[0].TypePacking;
                    newPallet.CumID = this.itemPalletContent[0].CumID;
                    newPallet.TypeEntry = this.itemPalletContent[0].TypeEntry;
                    newPallet.TypeCut = this.itemPalletContent[0].TypeCut;
                    newPallet.Lote = this.itemPalletContent[0].Lote;
                    newPallet.CbdID = this.itemPalletContent[0].CbdID;
                    newPallet.CmaID = this.itemPalletContent[0].CmaID;
                    let chambername = "";
                    chambername = this.SelectChamber.CdtDescription;  
                    if(chambername.length > 0)              
                    newPallet.PalletLocation = (chambername.substring(0, 3) + ' ' + chambername.slice(-1) + ' ' + 'CARRIL ' + parseInt(this.selectNumberLane + 1 + this.SelectSide.NumberStart)+ '/' +  this.Position[this.selectNumberLevel] + parseInt(this.selectPositionsLevel + 1 ));
                    newPallet.IncomePalletDetail = this.itemPalletContent;
                    
                    if(!this.Validate)
                    {
                        this.$fun.alert("Algun valor de cajas debe ser incorrecto ", "warning");
                        return; 
                    }
                    this.$fun.alert("Desea Guardar cambios ?", "question")
                    .then(res => {
                        if (res.value) 
                        {
                        _sFrzCameraIncomePallet.save(newPallet, this.$fun.getUserID())
                        .then( r => {
                            if(r.status == 200)
                            {                       
                                this.$fun.alert("Pallet ingresado correctamente", "success");

                                let PalletsOld = [];
                                this.itemPalletContent.forEach(item => {
                                    if(item.NumberBoxes != item.NumberBoxesOld)
                                    {                                         
                                        item.CdtID = item.CdtID;
                                        item.CipID = 0;
                                        item.NumberBoxes = item.NumberBoxesOld - parseInt(item.NumberBoxes);
                                        item.WeightNetBoxes = item.WeightNetBoxesOld - item.WeightNetBoxes;
                                        item.TotalBagWeight = item.WeightNetBoxes;
                                        item.PalletGroupIDS = item.PalletGroupID;
                                        item.NewPalletGroup = 1;//para crear el nuevo pallet con las cajas sobrantes           
                                        let detalle = [];
                                        let itemdetalle = {};
                                        itemdetalle.TypePacking = item.TypePacking;
                                        itemdetalle.TotalBox = item.NumberBoxes;
                                        itemdetalle.TotalBagWeight = item.TotalBagWeight;
                                        itemdetalle.TypeCultive = item.TypeCultive;
                                        itemdetalle.VrtID = item.VrtID;
                                        itemdetalle.TraceabilityID = item.TraceabilityID;
                                        itemdetalle.TypeCut = item.TypeCut;
                                        itemdetalle.CecID = item.CecID;
                                        itemdetalle.RcfIDs = item.RcfIDs;
                                        itemdetalle.DueDate = item.DueDate;
                                        itemdetalle.typeArmed = item.TypeArmed;
                                        itemdetalle.StateFinal = item.StateFinal;
                                        itemdetalle.TypeProduction = item.TypeProduction;
                                        itemdetalle.TypeVariant = item.TypeVariant;
                                        itemdetalle.IsObserved = item.IsObserved;
                                        itemdetalle.Observation = item.Observation;
                                        itemdetalle.TypeObservation = item.TypeObservation;
                                        itemdetalle.TypeGroup = item.TypeGroup;
                                        itemdetalle.TypeSubGroup = item.TypeSubGroup;
                                        detalle.push(itemdetalle);
                                        item.IncomePalletDetail = detalle;
                                        
                                        PalletsOld.push(item);                                    
                                    }
                                });
                           
                            
                            _sFrzCameraIncomePallet.saveOld(PalletsOld, this.$fun.getUserID())
                            .then( re => 
                            {

                            });                            
                                this.initialize();
                                this.itemsPalletDetail = [];
                                this.SelectSide = {};
                                this.SelectChamber = {};
                                this.PalletNew = r.data;
                                this.getDataPallet();                    
                            
                        }
                        });       
                        }
                            
                    });          
                }else{
                    this.$fun.alert("Agregue Pallets o Seleccione los Pallets para agrupar", "error");
                    return;
                }
            },
            Probar(item, nivel, posicion){                
                this.selectNumberLane = item;
                this.selectNumberLevel = nivel;
                this.selectPositionsLevel = posicion;
            },
            initialize(){
                _sChamber.list({CdtWarehouses: 1}, this.$fun.getUserID()).then((r) => {
                    if(r.status = 200)
                    {
                        this.listChamber = r.data;                        
                    }
                });
            },
            getDataPallet(){

                // 

                _sFrzCameraIncomePallet.getDataPallet(this.PalletNew, this.$fun.getUserID()).then((r) => {
                    if(r.status = 200)
                    {
                        console.log('QR', r.data);
                        this.PalletNew = r.data;
                        this.printQR();
                    }
                });
            },
            printQR() {    
                    var imgAll = document.querySelector("img");
                    
                    var Pagelink = "about:blank";
                    var pwa = window.open(Pagelink, "_new");
                    pwa.document.open();
                    pwa.document.write(
                        "<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style  media='print'> body{font-family: arial} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
						" @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
                    );
                        pwa.document.write(
                            "<div class='col col-6' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
                                imgAll.currentSrc +
                                "' />",
                        );
                            
                        pwa.document.write("<b style='font-size: 35px!important; z-index: 2;margin-top: 40px;border-radius: 20px;'>ID: " + 
                            this.PalletNew.PalletGroupID+"</b>"); 

                            pwa.document.write("</div><div style='margin: 30px 0px 0px 410px;'><table colspan='1' border='1'>");
                        pwa.document.write(
                            "<tr><td colspan='2' style='text-align:center;'><b>Sunshine Camaras</b></td>" + 
                            
                            "</tr>"+
                                "<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP-FR-213<br>Paleticket de identificación de pallet</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VERSION:04" +
                            "<br>F. creación:01/07/2024" +
                            "<br>F. última revisión:01/07/2024</td></tr>" +		
                            "<tr><td>Cliente</td><td>" + this.PalletNew.CumNombre + "</td></tr>"+
                            "<tr><td>Marca</td><td>" + this.PalletNew.BransName + "</td></tr>"+
                            "<tr><td>Materia Prima</td><td>" + this.PalletNew.TypeCultiveName + "</td></tr>"+
                            "<tr><td>Variedad</td><td>" + this.PalletNew.VrtDescription + "</td></tr>"+
                            "<tr><td>Trazabilidad</td><td>" + this.PalletNew.TraceabilityIDs + "</td></tr>"+
                            "<tr><td>Corte</td><td>" + this.PalletNew.TypeCutName + "</td></tr>"+
                            "<tr><td>Presentacion</td><td>" + this.PalletNew.TypePresentationName + "</td></tr>" +
                            "<tr><td>Cajas</td><td>" + this.PalletNew.NumberBoxes + " </td></tr>" + 
                            "<tr><td>Peso (Kg)</td><td>" + this.PalletNew.WeightNetBoxes + " KG.</td></tr>"+
                            "<tr><td>Estado Fianl</td><td>" + this.PalletNew.StateFinalString + " </td></tr>"							
							
					    );
					pwa.document.write("</table></div>");

                    pwa.document.write("</div></body></html>");
                    pwa.document.close();
               
				
			},  
        },        
        created(){
            this.initialize();
        }
    }
</script>
<style>
.resaltado {
    background-color: red;
}
.btn-flotante {
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 5px; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: #E91E63; /* Color de fondo */
	padding: 18px 30px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante:hover {
	background-color: #2c2fa5; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		right: 20px;
	}
} 

</style>